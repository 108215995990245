@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  height: 100%;
  width: 100%;
  font-family: "Poppins", sans-serif;
  background-color: #f8fafb;
  color: #000000;
}

html {
  scroll-behavior: smooth;
  height: 100%;
  width: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

a {
  background-color: transparent;
  background-color: transparent;
  text-decoration: none;
  color: #000000;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0 !important;
}

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}

img {
  width: 100%;
  height: auto;
}

button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
}
button:hover {
  transform: scale(1.05);
}

input {
  transition: 0.3s;
  border: none;
  outline: none;
}

.jobCardBtnContainer {
  width: 100%;
  max-width: 500px;
  margin-top: 40px;
  position: relative;
}
.jobCardBtnContainer .jobCardBtn {
  padding-top: 80px;
  background-color: #fff;
  border-radius: 15px;
  padding-bottom: 20px;
  height: 100%;
}
.jobCardBtnContainer .jobCardBtn .jobNumberContainer {
  display: grid;
  justify-items: center;
}
.jobCardBtnContainer .jobCardBtn .jobNumberContainer .jobNumberContent {
  background-color: #00aa3a;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 20px;
  padding: 2px 30px;
}
.jobCardBtnContainer .jobCardBtn .jobNumberContainer .jobNumberContent p {
  color: #fff;
}
.jobCardBtnContainer .jobCardBtn .textContainer {
  text-align: center;
  padding: 15px 10px;
}
.jobCardBtnContainer .jobCardBtn .textContainer .jobTitle {
  color: #000000;
  font-size: 20px;
  font-weight: 500;
}
.jobCardBtnContainer .jobCardBtn .textContainer .companyTitle {
  color: rgba(0, 0, 0, 0.8);
  font-size: 18px;
}
.jobCardBtnContainer .jobCardBtn .btnContainer {
  display: grid;
  place-items: center;
}
.jobCardBtnContainer .jobImageContainer {
  position: absolute;
  top: -50px;
  left: 0;
  width: 100%;
  display: grid;
  place-items: center;
}
.jobCardBtnContainer .jobImageContainer .imgContainer {
  background-color: #004aad;
  height: 100px;
  width: 100px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  font-size: 37px;
  color: #fff;
}