@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  height: 100%;
  width: 100%;
  font-family: "Poppins", sans-serif;
  background-color: #f8fafb;
  color: #000000;
}

html {
  scroll-behavior: smooth;
  height: 100%;
  width: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

a {
  background-color: transparent;
  background-color: transparent;
  text-decoration: none;
  color: #000000;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0 !important;
}

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}

img {
  width: 100%;
  height: auto;
}

button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
}
button:hover {
  transform: scale(1.05);
}

input {
  transition: 0.3s;
  border: none;
  outline: none;
}

.subHeading1 {
  font-size: 24px;
  font-weight: 600;
}

.subHeading1_light {
  color: #fff;
}

.subHeading1_dark {
  color: rgba(0, 0, 0, 0.8);
}